// アイキャッチスライダー
$(function () {
  $('.sliderlist_eyecatch').slick({
    autoplay: true,
    autoplaySpeed: 0,
    speed: 12000,
    cssEase: 'linear',
    swipe: true,
    arrows:false,
    pauseOnFocus: true,
    pauseOnHover: true,
    centerMode: false,
    variableWidth: true
  });
});

// ドロワーナビ
$(function () {
  $('.globalheader-button').on('click',function(){
    $('.globalheader-button').toggleClass('close');
    $('.globalnav').fadeToggle(300);
    $('body').toggleClass('noscroll');
  });
});

// ページトップ
$(function () {
  let pagetop = $('#page-top');
  pagetop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
});

// 詳細画面スライダー
$(function () {
  $('.imagelist-primary').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true,
    asNavFor: '.imagelist-secondary',
    mobileFirst: true,
    speed: 0,
    responsive: [ {
      breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
    } ]
  });

  $('.imagelist-secondary').slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    asNavFor: '.imagelist-primary',
    dots: false,
    variableWidth: true,
    focusOnSelect: true,
    mobileFirst: true,
    responsive: [ {
      breakpoint: 768,
      settings: 'unslick'
    } ]
  });
});

$(window).on('resize orientationchange', function() {
  $('.sliderlist_eyecatch').slick('resize');
  $('.imagelist-primary').slick('resize');
  $('.imagelist-secondary').slick('resize');
});

$(function () {
  $(".imagelist-secondary img").on("click", function () {
    img = $(this).attr("src");
    $(".slick-overlay-image").remove();
    $(".imagelist-primary").prepend('<div class="slick-overlay-image"><img src="'+img+'" alt=""></div>');
  });
});

$(function () {
  $(".slick-prev").on("click", function () {
    $(".slick-overlay-image").remove();
  });
  $(".slick-next").on("click", function () {
    $(".slick-overlay-image").remove();
  });
});

// 詳細画面タブ
$(function () {
  let tabs = $(".tabarea-tab");
  $(".tabarea-tab").on("click", function() {
    $(".active").removeClass("active");
    $(this).addClass("active");
    const index = tabs.index(this);
    $(".tabarea-tabpanel").removeClass("show").eq(index).addClass("show");
  })
})

// まとめて問い合せ
$(function () {
  $(".item-check").on("change", function() {
    $(".button_estimate").addClass("display");
    $(".linklist_floatcontact button:nth-child(1)").removeClass("display");
    $(".linklist_floatcontact button:nth-child(2)").addClass("display");
    let check_count = $('.item_large :checked').length;
    if (check_count == 0){
      $(".button_estimate").removeClass("display");
      $(".linklist_floatcontact button:nth-child(2)").removeClass("display");
      $(".linklist_floatcontact button:nth-child(1)").addClass("display");
    }
  })
})

$(function () {
  let check_count = $('.item_large :checked').length;
  $(".button_estimate").removeClass("display");
  $(".linklist_floatcontact button:nth-child(1)").addClass("display");
  $(".linklist_floatcontact button:nth-child(2)").removeClass("display");
  if (check_count > 0){
    console.log("test");
    $(".button_estimate").addClass("display");
    $(".linklist_floatcontact button:nth-child(1)").removeClass("display");
    $(".linklist_floatcontact button:nth-child(2)").addClass("display");
  }
})

// 追従コンタクト
$(function () {
  let floatContact = $('.linklist_floatcontact');
  floatContact.removeClass('show');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      floatContact.addClass('show');
    } else {
      floatContact.removeClass('show');
    }
  });
});

// 追従ヘッダー
$(function () {
  let start_position = 0,
      window_position,
      $window = $(window),
      $header = $('.globalheader');
  $window.on( 'scroll' , function(){
    window_position = $(this).scrollTop();
    if (window_position <= start_position) {
      $header.removeClass('hidden');
    } else {
      $header.addClass('hidden');
    }
    if (window_position <= 50) {
      $header.removeClass('hidden');
    }
    start_position = window_position;
  });
  $window.trigger('scroll');
});

// 追従詳細ナビ
$(function () {
  let floatContact = $('.summarynavigation');
  floatContact.removeClass('show');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      floatContact.addClass('show');
    } else {
      floatContact.removeClass('show');
    }
  });
});

// 絞り込み検索
$(function () {
  $('.filterlist-button').on('click',function(){
    $('.searchbox').toggleClass('close');
    $('.searchbox').fadeToggle(300);
    $('body').toggleClass('noscroll');
  });
});

// object-fit(ie)
$(function () {
  objectFitImages()
});

